import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Spin,
} from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const GolfLinks = {
  shadow: {
    name: "ShadowRidgeGCV3",
    courses: "1",
  },
  shannon: {
    name: "ShannonLakeV3",
    courses: "1,2",
  },
  springs: {
    name: "KelownaV3",
    courses: "1,2,3",
  },
};

const sort = (a, b) => {
  return new Date("1970/01/01 " + a.time) - new Date("1970/01/01 " + b.time);
};

const TeeTime = ({ teeTimes, isLoading, searchDate, players }) => {
  return (
    <div>
      <Row gutter={16}>
        {isLoading || !searchDate ? (
          <div>
            <Spin />
          </div>
        ) : teeTimes.length > 0 ? (
          teeTimes.map((teeTime, index) => {
            if (!teeTime.time) {
              return <></>;
            }

            const hour = teeTime.time.split(":")[0];
            const ampm = teeTime.time.split(" ")[1];
            const href = `https://w.cps.golf/${teeTime.name}/Home/nIndex?CourseId=${teeTime.courses}&Date=${searchDate}&Time=${hour}${ampm}&Player=${players}&Hole=Any`;
            return (
              <Space>
                <Col span={3} key={index}>
                  <Space>
                    <Card title={teeTime.time} style={{ width: 300 }}>
                      <p>
                        <strong>{teeTime.course}</strong>
                      </p>
                      <p>{teeTime.price}</p>
                      <p>{teeTime.players}</p>
                      <Button href={href} target="_blank" type="primary">
                        Book Now
                      </Button>
                    </Card>
                  </Space>
                </Col>
              </Space>
            );
          })
        ) : (
          "No Tee Times Available"
        )}
        <Divider />
      </Row>
    </div>
  );
};

const playerOptions = [
  { label: "Any", value: "99" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
];

const App = () => {
  const [searchDate, setSearchDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [players, setPlayers] = useState(playerOptions[0].value);
  const [data, setData] = useState([]);

  const playerChange = (e) => {
    setPlayers(() => e.target.value);
  };

  useEffect(() => {
    setIsLoading(() => true);

    const shadow = `https://us-central1-golf-search-prod.cloudfunctions.net/webApi?ids=${GolfLinks.shadow.courses}&name=${GolfLinks.shadow.name}&date=${searchDate}&players=${players}`;
    const shannon = `https://us-central1-golf-search-prod.cloudfunctions.net/webApi?ids=${GolfLinks.shannon.courses}&name=${GolfLinks.shannon.name}&date=${searchDate}&players=${players}`;
    const springs = `https://us-central1-golf-search-prod.cloudfunctions.net/webApi?ids=${GolfLinks.springs.courses}&name=${GolfLinks.springs.name}&date=${searchDate}&players=${players}`;
    const tower = `https://www.chronogolf.com/marketplace/clubs/18058/teetimes?date=${searchDate}&course_id=20999&affiliation_type_ids%5B%5D=82205&nb_holes=18`;
    (async () => {
      const responses = await Promise.all([
        fetch(shadow),
        fetch(shannon),
        fetch(springs),
      ]);

      const json = await Promise.all(
        responses.map((response) => response.json())
      );

      const combined = [
        ...json[0].map((time) => ({
          ...time,
          course: "Shadow Ridge",
          ...GolfLinks.shadow,
        })),
        ...json[1].map((time) => ({
          ...time,
          course: "Shannon Lake",
          ...GolfLinks.shannon,
        })),
        ...json[2].map((time) => ({
          ...time,
          course: "Kelowna Springs",
          ...GolfLinks.springs,
        })),
        // ...json[3]
        //   .filter((time) => time.green_fees)
        //   .map((time) => ({
        //     time: time.start_time,
        //     price: time.green_fees[0].price,
        //     players: time.green_fees.length,
        //     course: "Tower Ranch",
        //     ...GolfLinks.springs,
        //   })),
      ].sort(sort);

      setData(() => combined);
      setIsLoading(() => false);
    })();
  }, [searchDate, players]);

  return (
    <div class="container">
      <h2>{searchDate}</h2>
      <Form layout="inline">
        <Form.Item label="Date:">
          <DatePicker
            onChange={(date) => {
              setSearchDate(() => date.format("YYYY-M-DD"));
            }}
            format="YYYY-M-DD"
          />
        </Form.Item>

        <Form.Item label="Players:">
          <Radio.Group
            options={playerOptions}
            onChange={playerChange}
            value={players}
            optionType="button"
          />
        </Form.Item>
      </Form>

      <div className="App">
        <TeeTime
          isLoading={isLoading}
          teeTimes={data.length > 0 && data}
          searchDate={searchDate}
          players={players}
        />
      </div>
    </div>
  );
};

export default App;
